.flip-clock {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  -webkit-user-select: none;
  text-align: center;
  position: relative;
  width: 100%;
  display: inline-flex;
  font-size: 1vw;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  box-sizing: border-box;
  align-items: flex-end; }
  .flip-clock .flip-clock-group {
    display: flex;
    position: relative; }
    .flip-clock .flip-clock-group .flip-clock-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      font-size: 1em;
      height: 2em;
      line-height: 2em;
      font-weight: 400;
      text-transform: capitalize;
      transform: translateY(-100%); }
      .flip-clock .flip-clock-group .flip-clock-label.flip-clock-meridium {
        font-size: 1.75em;
        line-height: 1.75em;
        top: 50%;
        left: 100%;
        flex: 0;
        width: auto;
        text-transform: uppercase;
        font-weight: 200;
        transform: translate(0.5em, -50%); }
    .flip-clock .flip-clock-group .flip-clock-list {
      width: 4em;
      height: 6em;
      position: relative;
      border-radius: .75rem;
      box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.24), 0 3px 8px rgba(0, 0, 0, 0.05);
      font-weight: bold;
      color: #ccc; }
      .flip-clock .flip-clock-group .flip-clock-list:not(:last-child) {
        margin-right: .333em; }
      .flip-clock .flip-clock-group .flip-clock-list:not(.flip) .active .flip-clock-list-item-inner {
        z-index: 4; }
      .flip-clock .flip-clock-group .flip-clock-list:not(.flip) .flip-clock-list-item-inner .top:after,
      .flip-clock .flip-clock-group .flip-clock-list:not(.flip) .flip-clock-list-item-inner .bottom:after {
        display: none; }
      .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner {
        position: absolute;
        width: 100%;
        height: 100%; }
      .flip-clock .flip-clock-group .flip-clock-list.flip {
        animation-delay: 500ms;
        animation-duration: 500ms; }
        .flip-clock .flip-clock-group .flip-clock-list.flip .flip-clock-list-item-inner {
          perspective: 15em; }
        .flip-clock .flip-clock-group .flip-clock-list.flip .top,
        .flip-clock .flip-clock-group .flip-clock-list.flip .bottom,
        .flip-clock .flip-clock-group .flip-clock-list.flip .active,
        .flip-clock .flip-clock-group .flip-clock-list.flip .active > div,
        .flip-clock .flip-clock-group .flip-clock-list.flip .before,
        .flip-clock .flip-clock-group .flip-clock-list.flip .before > div {
          animation-delay: inherit;
          animation-fill-mode: forwards;
          animation-duration: inherit;
          animation-timing-function: ease-in; }
          .flip-clock .flip-clock-group .flip-clock-list.flip .top:after,
          .flip-clock .flip-clock-group .flip-clock-list.flip .bottom:after,
          .flip-clock .flip-clock-group .flip-clock-list.flip .active:after,
          .flip-clock .flip-clock-group .flip-clock-list.flip .active > div:after,
          .flip-clock .flip-clock-group .flip-clock-list.flip .before:after,
          .flip-clock .flip-clock-group .flip-clock-list.flip .before > div:after {
            animation-duration: inherit;
            animation-fill-mode: inherit;
            animation-timing-function: inherit; }
        .flip-clock .flip-clock-group .flip-clock-list.flip .before {
          animation-delay: 0s; }
          .flip-clock .flip-clock-group .flip-clock-list.flip .before .top {
            animation-name: flip-top; }
          .flip-clock .flip-clock-group .flip-clock-list.flip .before .top:after,
          .flip-clock .flip-clock-group .flip-clock-list.flip .before .bottom:after {
            animation-name: show-shadow; }
        .flip-clock .flip-clock-group .flip-clock-list.flip .active > div {
          animation-name: indexing; }
        .flip-clock .flip-clock-group .flip-clock-list.flip .active .top:after,
        .flip-clock .flip-clock-group .flip-clock-list.flip .active .bottom:after {
          animation-delay: calc(500ms * .15);
          animation-name: hide-shadow; }
        .flip-clock .flip-clock-group .flip-clock-list.flip .active .bottom {
          animation-name: flip-bottom; }
      .flip-clock .flip-clock-group .flip-clock-list .active {
        z-index: 2; }
        .flip-clock .flip-clock-group .flip-clock-list .active .bottom {
          z-index: 2;
          transform-origin: top center; }
      .flip-clock .flip-clock-group .flip-clock-list .before {
        z-index: 3; }
        .flip-clock .flip-clock-group .flip-clock-list .before .top {
          z-index: 2;
          transform-origin: bottom center; }
          .flip-clock .flip-clock-group .flip-clock-list .before .top:after {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%); }
        .flip-clock .flip-clock-group .flip-clock-list .before .bottom:after {
          background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%); }
      .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: rotateX(0.0001deg); }
        .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner:first-child {
          z-index: 2; }
        .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner > .top,
        .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner > .bottom {
          width: 100%;
          height: 50%;
          overflow: hidden;
          position: relative;
          font-size: 4.5em;
          background: #333;
          box-shadow: inset 0 0 0.2em rgba(0, 0, 0, 0.5); }
          .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner > .top:after,
          .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner > .bottom:after {
            content: " ";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden; }
          .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner > .top:before,
          .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner > .bottom:before {
            content: " ";
            display: block;
            width: 100%;
            height: 1px;
            position: absolute; }
        .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner .top {
          border-radius: .75rem .75rem 0 0;
          line-height: 1.33333; }
          .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner .top:after {
            border-radius: .75rem .75rem 0 0; }
          .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner .top:before {
            background: #333;
            opacity: .4;
            bottom: 0; }
        .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner .bottom {
          border-radius: 0 0 .75rem .75rem;
          line-height: 0; }
          .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner .bottom:after {
            border-radius: 0 0 .75rem .75rem; }
          .flip-clock .flip-clock-group .flip-clock-list .flip-clock-list-item-inner .bottom:before {
            background: #ccc;
            opacity: .1; }
  .flip-clock .flip-clock-divider {
    position: relative;
    width: 1.5em;
    height: 6em; }
    .flip-clock .flip-clock-divider:before, .flip-clock .flip-clock-divider:after {
      content: " ";
      display: block;
      width: .75em;
      height: .75em;
      background: #333;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%; }
    .flip-clock .flip-clock-divider:before {
      transform: translate(-50%, 75%); }
    .flip-clock .flip-clock-divider:after {
      transform: translate(-50%, -175%); }

@keyframes indexing {
  0% {
    z-index: 2; }
  1% {
    z-index: 3; }
  100% {
    z-index: 4; } }

@keyframes flip-bottom {
  0% {
    transform: rotateX(90deg); }
  100% {
    transform: rotateX(0); } }

@keyframes flip-top {
  0% {
    transform: rotateX(0); }
  100% {
    transform: rotateX(-90deg); } }

@keyframes show-shadow {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes hide-shadow {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
